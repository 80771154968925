import BoxSection from "components/program/box-section"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import Top from "components/program/top"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function AkilliTahtaKilit({ data }) {
  const {
    genel: {
      dahaFazlaBilgiMiGerekli,
      ozellikler,
      ekranGoruntuleri,
      akilliTahtaKilit,
    },
    akilliKilit: {
      seoTitle,
      seoDescription,
      baslik,
      altBaslik,
      altBaslikAlti,
      kullaniciMesaji,
      kullaniciAdi,
      indirButonuYazi,
      butonAltiYazi,
      seoFooterParagraf1,
      seoFooterParagraf2,
      seoFooterParagraf3,
      bolumler,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/akilli-tahta-kilit"
        datePublished="2017-06-23T23:10:31+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container>
        <Top
          headerText={baslik}
          headerBottomText={altBaslik}
          useFreeText={altBaslikAlti}
          quoteText={kullaniciMesaji}
          quoteAuthor={kullaniciAdi}
          buttonText={indirButonuYazi}
          buttonLink="#"
          buttonBottomText={butonAltiYazi}
          buttonColor="#b9341c"
          containerStyle={{ paddingBottom: 0 }}
        >
          <Img fluid={data.akillikilitBilgisayar.childImageSharp.fluid} />
        </Top>
      </Container>
      <div className="my-5"></div>
      {/*  1. En Hızlı Çekiliş Sitesi*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[0].baslik}
        descriptionText={bolumler[0].aciklama}
        listItems={bolumler[0].maddeler}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.cift.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. Çekilişinizi Kaydedin*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[1].baslik}
        descriptionText={bolumler[1].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.guvenlik.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 3.Farklı araçlar kullanın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[2].baslik}
        descriptionText={bolumler[2].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.rapor.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. Çekilişinizi Kaydedin*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[3].baslik}
        descriptionText={bolumler[3].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.kontrol.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 3.Farklı araçlar kullanın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[4].baslik}
        descriptionText={bolumler[4].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.mobil.childImageSharp.fluid} />
        </div>
      </BoxSection>

      <div className="text-center mt-5">
        <h2>{akilliTahtaKilit}</h2>
        <p>{seoFooterParagraf1}</p>
        <p>{seoFooterParagraf2}</p>
        <p>{seoFooterParagraf3}</p>
      </div>

      <BoxSection containerStyle={{ paddingTop: 0 }}>
        <h5 className="text-center">{dahaFazlaBilgiMiGerekli}</h5>

        <ul className="list-unstyled text-center m-0" style={{ fontSize: 16 }}>
          <li className="mb-0">
            <LocalizedLink to="/akilli-tahta-kilit/ozellikler">
              {ozellikler}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/akilli-tahta-kilit/ekran-goruntuleri">
              {ekranGoruntuleri}
            </LocalizedLink>
          </li>
        </ul>
      </BoxSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    akillikilitBilgisayar: file(
      relativePath: { eq: "akilli-kilit/akilli-kilitlaptopta.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cift: file(relativePath: { eq: "cekilis/cift.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    guvenlik: file(relativePath: { eq: "akilli-kilit/guvenlik.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rapor: file(relativePath: { eq: "akilli-kilit/rapor.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kontrol: file(relativePath: { eq: "akilli-kilit/kontrol.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobil: file(relativePath: { eq: "akilli-kilit/mobil.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

AkilliTahtaKilit.propTypes = {
  data: PropTypes.object,
}
